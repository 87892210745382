import axios from "axios";

import { IS_LOADING, baseURL, sendOtp_URL, sendOtpReg, verifyOtp_URL, verifyOtpReg, getProfileURL, getProfile, getStatecity, getStatecityURL, freelancerRegisterURL, freelancerRegisterReg } from "../../Constant";
import { toast } from "react-toastify";
toast.configure();

function getAsios(token) {
  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: "bearer " + token,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("token", JSON.stringify(tokenDetails));
}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}


// api call all function (Parth Roka)
export function sendOtpAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(sendOtp_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          localStorage.setItem(sendOtp_URL, true);
          localStorage.setItem("insertedId", response.data.data.insertedId);
          localStorage.setItem("isregister", response.data.data.isregister);
          localStorage.setItem("freelancerId", response.data.data.freelancerId);
          dispatch({ type: sendOtpReg, payload: response.data });
          if (response.data.data.isregister == true) {
            window.location.href = window.location.origin + `/otp`;
          } else {
            window.location.href = window.location.origin + `/Personaldetails`;
          }
        } else {
          toast.error(response.data.message, {
            style: { fontSize: 14 },
            autoClose: 10000,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        toast.error("Something went wrong, please try again", {
          style: { fontSize: 14 },
          autoClose: 3000,
        });
      });
  };
}


export function verifyOtpAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(verifyOtp_URL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: verifyOtpReg, payload: response.data.data });
          localStorage.setItem("islogged", true);
          localStorage.setItem(verifyOtp_URL, true);
          localStorage.setItem("freelancerId", response.data.data.freelancerId);
          localStorage.setItem("step", response.data.data.step);
          window.location.href = window.location.origin + "/home";
        } else {
          toast.error(response.data.message, {
            style: { fontSize: 14 },
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        toast.error("Something went wrong, please try again", {
          style: { fontSize: 14 },
          autoClose: 3000,
        });
      });
  };
}


export function getProfileAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(getProfileURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          if (response.data.data) {
            localStorage.setItem("firstname", response.data.data.firstname);
            localStorage.setItem("status", response.data.data.status);
          }
          dispatch({ type: getProfile, payload: response.data.data });
        } else {
          toast.error(response.data.message, {
            style: { fontSize: 14 },
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        toast.error("Something went wrong, please try again", {
          style: { fontSize: 14 },
          autoClose: 3000,
        });
      });
  };
}


export function getStatecityAPI(data) {
  return function (dispatch) {
    return getAsios()
      .get(getStatecityURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: getStatecity, payload: response.data.data });
        } else {
          toast.error(response.data.message, {
            style: { fontSize: 14 },
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        toast.error("Something went wrong, please try again", {
          style: { fontSize: 14 },
          autoClose: 3000,
        });
      });
  };
}


export function freelancerRegisterAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(freelancerRegisterURL, data)
      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        if (response.data.errorcode == 0) {
          dispatch({ type: freelancerRegisterReg, payload: response.data });
          localStorage.setItem("insertedId", response.data.data.insertedId);
        } else {
          toast.error(response.data.message, {
            style: { fontSize: 14 },
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        toast.error("Something went wrong, please try again", {
          style: { fontSize: 14 },
          autoClose: 3000,
        });
      });
  };
}
