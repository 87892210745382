//Staging URL 
export const baseURL = "https://cms-api.smartexams.in/psapi/";

//LIVE URL
// export const baseURL = "https://psapi.edu-test.in/psapi/";

export const IS_LOADING = "IS_LOADING";

export const sendOtp_URL = "sendotp";
export const sendOtpReg = "otpReg";

export const verifyOtp_URL = "verifyotp";
export const verifyOtpReg = "verifyOtpReg";

export const getProfileURL = "getprofile";
export const getProfile = "getProfile";

export const getStatecityURL = "getstatecity";
export const getStatecity = "getStatecity";

export const freelancerRegisterURL = "freelancerRegister";
export const freelancerRegisterReg = "freelancerRegisterReg";

