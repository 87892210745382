import React, { useEffect } from "react";
import Phone from "./Component/Login/Login";
import Otp from "./Component/Otp/Otp";
import PersonalDetails from "./Component/PersonalDetails/PersonalDetails";
import Home from "./Component/Home/Home";
import Loader from "./Component/Loader/Loader";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    if (window.location.href == window.location.origin + "/") {
      window.location.href = window.location.origin + "/login";
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/login" element={<Phone />} />
          <Route exact path="/otp" element={<Otp />} />
          <Route exact path="/Personaldetails" element={<PersonalDetails />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/Loader" element={<Loader />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
