
import { sendOtpReg, verifyOtpReg, getStatecity, getProfile, freelancerRegisterReg } from '../../Constant';

let defaultState = {
  sendOtpReg: null,
  verifyOtpReg: null,
  getProfile: null,
  freelancerRegisterReg: null,
  getStatecity: null,
}

const globalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case sendOtpReg:
      return Object.assign({}, state, {
        sendOtpReg: action.payload
      });

    case verifyOtpReg:
      return Object.assign({}, state, {
        verifyOtpReg: action.payload
      });

    case getProfile:
      return Object.assign({}, state, {
        getProfile: action.payload
      });
    case getStatecity:
      return Object.assign({}, state, {
        getStatecity: action.payload
      });

    case freelancerRegisterReg:
      return Object.assign({}, state, {
        freelancerRegisterReg: action.payload
      });

    default:
      return state;
  }
};
export default globalReducer;

