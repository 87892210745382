import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
import { sendOtpAPI, dispatchLoadingStatus } from "../../API/Action/globalAction";
import { useDispatch } from "react-redux";
import logo from '../../assets/images/logo.png';

// Added By Parth Roka
function Login() {
  const [Phone1, setPhone] = useState("");

  const dispatch = useDispatch({});
  let errorsObj = { Phone: "" };
  const [error, setError] = useState(errorsObj);

  // submit button (parth roka)
  const PhoneNumber = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (!Phone1) {
      errorObj.Phone = "Please enter your mobile number";
      error = true;
    } else if (Phone1.length != 10) {
      errorObj.Phone = "Please enter valid mobile number";
      error = true;
    } else {
      let formData = new FormData();
      formData.append("phone", Phone1);
      localStorage.setItem("UserNumber", Phone1);
      dispatch(dispatchLoadingStatus(true));
      dispatch(sendOtpAPI(formData));
    }
    setError(errorObj);
  };

  // validation (parth roka)
  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Loader />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body ">
                <div className="auth-logo">
                  <a href="" className="auth-logo-dark">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title ">
                        <img src={logo} alt="" className="" height="" />
                      </span>
                    </div>
                  </a>
                </div>
                <div className="p-2">
                  <form className="form-horizontal" action="">
                    <div className="div mb-3">
                      <label htmlFor="username" className="form-label">
                        Mobile Number
                      </label>
                      <div className="input-group">
                        <div className="input-group-text">+91</div>
                        <input type="text" className="form-control" id="username" placeholder="Enter Mobile Number" name="number" value={Phone1} onChange={NumValid} maxLength="10" />
                      </div>
                      {error.Phone && <div className="error">{error.Phone} </div>}
                    </div>

                    <div className="mt-3 d-grid">
                      <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={PhoneNumber}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
