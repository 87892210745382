import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { dispatchLoadingStatus, getProfileAPI, getStatecityAPI, freelancerRegisterAPI } from "../../API/Action/globalAction";
import { useForm, Controller } from "react-hook-form";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import logo from '../../assets/images/logo.png';

registerLocale("fr", fr);



// Added By Parth Roka
function PersonalDetails() {
  const [dob, setDOB] = useState("");
  const [errorsdob, seterrorsdob] = useState(false);
  const [citylist, setcitylist] = useState([]);
  const [newPhone, setNewPhone] = useState("");

  const dispatch = useDispatch({});

  let history = useNavigate();
  const getProfile = useSelector((state) => state.globalReducer.getProfile);
  let statelist = useSelector((state) => state.globalReducer.getStatecity);
  const freelancerRegisterReg = useSelector((state) => state.globalReducer.freelancerRegisterReg);
  let UserNumber = localStorage.getItem("UserNumber");

  useEffect(() => {
    if (freelancerRegisterReg) {
      if (freelancerRegisterReg.errorcode == 0) {
        history("/otp");
      }
    }
  }, [freelancerRegisterReg]);

  useEffect(() => {
    if (statelist && getProfile) {
      var filter = statelist.filter((i) => i._id == getProfile.state);
      if (filter) {
        if (filter[0]) {
          setcitylist(filter[0].districts);
          reset({ city: getProfile.city, state: getProfile.state });
        }
      }
    }
  }, [statelist && getProfile]);

  const freelancerId = localStorage.getItem("freelancerId");

  // Api Call (Parth Roka)
  useEffect(() => {
    const isregister = localStorage.getItem("isregister");
    const status = localStorage.getItem("status");
    if (isregister == "false" || status == "2") {
      let formData = new FormData();
      formData.append("freelancerId", localStorage.getItem("freelancerId"));
      dispatch(dispatchLoadingStatus(true));
      dispatch(getProfileAPI(formData));
      dispatch(getStatecityAPI());
      history("/Personaldetails");
    } else {
      history("/login");
    }
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [chnageButton, setChangeButton] = useState(false);
  const [wrongNumber, setWrongNumber] = useState(false);
  const [wrongNumber22, setWrongNumber22] = useState(false);
  const [wrongNumber33, setWrongNumber33] = useState(false);

  const [fnameError, setFormFnameError] = useState(false);
  const [fnameError22, setFormFnameError22] = useState(false);
  const [fnameError33, setFormFnameError33] = useState(false);

  const [formEmailError, setFormEmailError] = useState(false);
  const [formEmailError22, setFormEmailError22] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function handleFormId() {
    let pattern = /^[a-zA-Z\s]*$/;

    let fname = document.getElementById("fname").value;
    let username = document.getElementById("username").value;

    if (fname) {
      setFormFnameError(false);

      if (!pattern.test(fname)) {
        setFormFnameError22(true);
      } else {
        setFormFnameError22(false);
      }

      if (fname.length >= 3) {
        setFormFnameError33(false);
      } else {
        setFormFnameError33(true);
      }
    } else {
      setFormFnameError(true);
    }

    if (username) {
      setFormEmailError(false);
      if (!isValidEmail(username)) {
        setFormEmailError22(true);
      } else {
        setFormEmailError22(false);
      }
    } else {
      setFormEmailError(true);
    }

    let lname = document.getElementById("lname").value;
    let altrphone = document.getElementById("altrphone").value;
    let CurrentAddress = document.getElementById("CurrentAddress").value;
    let state = document.getElementsByName("stateName").value;
    let city = document.getElementById("city").value;
    let pincode = document.getElementById("pincode").value;

    let altphonePattern = /^[0-9\b]+$/;

    if (altrphone) {
      if (altrphone.length == 10) {
        setWrongNumber22(false);
        if (altphonePattern.test(altrphone)) {
          setWrongNumber33(false);
          if (altrphone == UserNumber) {
            setWrongNumber(true);
          } else {
            setWrongNumber(false);
          }
        } else {
          setWrongNumber33(true);
        }
      } else {
        setWrongNumber22(true);
      }
    }

    if (fname && pattern.test(fname) && fname.length >= 3 && username && isValidEmail(username)) {
      if (altrphone) {
        if (altrphone != UserNumber && altrphone.length == 10 && altphonePattern.test(altrphone)) {
          setWrongNumber22(false);
          setWrongNumber33(false);
          setWrongNumber(false);
          let formData = new FormData();
          formData.append("freelancerId", freelancerId);
          formData.append("firstname", fname);
          localStorage.setItem("firstname", fname);
          formData.append("lastname", lname ? lname : "");
          formData.append("email", username);
          formData.append("altrphone", altrphone ? altrphone : "");
          formData.append("dob", dob ? moment(dob).format("DD/MM/yyyy") : "");
          formData.append("address", CurrentAddress ? CurrentAddress : "");
          formData.append("city", city ? city : "");
          formData.append("state", dropDownState ? dropDownState : "");
          formData.append("pincode", pincode ? pincode : "");
          dispatch(dispatchLoadingStatus(true));
          dispatch(freelancerRegisterAPI(formData));
        } else {
        }
      } else {
        setWrongNumber22(false);
        setWrongNumber33(false);
        setWrongNumber(false);
        let formData = new FormData();
        formData.append("freelancerId", freelancerId);
        formData.append("firstname", fname);
        localStorage.setItem("firstname", fname);
        formData.append("lastname", lname ? lname : "");
        formData.append("email", username);
        formData.append("altrphone", altrphone ? altrphone : "");
        formData.append("dob", dob ? moment(dob).format("DD/MM/yyyy") : "");
        formData.append("address", CurrentAddress ? CurrentAddress : "");
        formData.append("city", city ? city : "");
        formData.append("state", dropDownState ? dropDownState : "");
        formData.append("pincode", pincode ? pincode : "");
        dispatch(dispatchLoadingStatus(true));
        dispatch(freelancerRegisterAPI(formData));
      }
    }
  }

  // Submit Button (Parth Roka)
  const onSubmit = (data) => {
    let altphonePattern = /^[0-9\b]+$/;

    if (data.altrphone) {
      if (data.altrphone.length == 10) {
        setWrongNumber22(false);
        if (altphonePattern.test(data.altrphone)) {
          setWrongNumber33(false);
          if (data.altrphone == UserNumber) {
            setWrongNumber(true);
          } else {
            setWrongNumber(false);
          }
        } else {
          setWrongNumber33(true);
        }
      } else {
        setWrongNumber22(true);
      }
    }

    if (freelancerId && data.fname && data.email && dob && data.CurrentAddress && data.city && data.state && data.pincode) {
      if (data.altrphone) {
        if (data.altrphone != UserNumber && data.altrphone.length == 10 && altphonePattern.test(data.altrphone)) {
          let formData = new FormData();
          formData.append("freelancerId", freelancerId);
          formData.append("firstname", data.fname);
          localStorage.setItem("firstname", data.fname);
          formData.append("lastname", data.lastname);
          formData.append("email", data.email);
          formData.append("altrphone", data.altrphone);
          formData.append("dob", dob ? moment(dob).format("DD/MM/yyyy") : "");
          formData.append("address", data.CurrentAddress);
          formData.append("city", data.city);
          formData.append("state", data.state);
          formData.append("pincode", data.pincode);
          dispatch(dispatchLoadingStatus(true));
          dispatch(freelancerRegisterAPI(formData));
        }
      } else {
        let formData = new FormData();
        formData.append("freelancerId", freelancerId);
        formData.append("firstname", data.fname);
        localStorage.setItem("firstname", data.fname);
        formData.append("lastname", data.lastname);
        formData.append("email", data.email);
        formData.append("dob", dob ? moment(dob).format("DD/MM/yyyy") : "");
        formData.append("address", data.CurrentAddress);
        formData.append("city", data.city);
        formData.append("state", data.state);
        formData.append("pincode", data.pincode);
        dispatch(dispatchLoadingStatus(true));
        dispatch(freelancerRegisterAPI(formData));
      }
    }
  };

  useEffect(() => {
    if (getProfile) {
      var x = {
        fname: getProfile.firstname,
        lastname: getProfile.lastname,
        email: getProfile.email,
        altrphone: getProfile.altrphone,
        birthDate: getProfile.dob ? moment(getProfile.dob, "dd/MM/yyyy") : "",
        qualification: getProfile.qualification,
        workexperience: getProfile.experience,
        WorkDetails: getProfile.experiencedetails,
        CurrentAddress: getProfile.address,
        city: getProfile.city,
        state: getProfile.state,
        pincode: getProfile.pincode,
      };
      reset(x);

      if (getProfile.dob) {
        var dob = moment(getProfile.dob, "DD/MM/yyyy").format("MM/DD/yyyy");
        setDOB(new Date(dob));
      }
      if (getProfile.state) {
        setDropDownState(getProfile.state);
      }
      if (getProfile.phone) {
        setNewPhone(getProfile.phone);
        localStorage.setItem("UserNumber", getProfile.phone);
      }
    }
  }, [getProfile && statelist]);

  const [dropDownState, setDropDownState] = useState("");

  const stateFun = (e) => {
    var filter = statelist.filter((i) => i._id == e.target.value);
    setDropDownState(e.target.value);
    if (filter && e.target.value) {
      if (filter[0]) {
        setcitylist(filter[0].districts);
        reset({ city: "" });
      }
    } else {
      setcitylist([]);
      reset({ city: "" });
    }
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Loader />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10 ">
            <div className="card">
              <div className="card-body ">
                <div className="auth-logo">
                  <a href="" className="auth-logo-dark">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title ">
                        <img src={logo} alt="" className="" height="" />
                      </span>
                    </div>
                  </a>
                </div>
                <div className="p-2">
                  <form className="form-horizontal row" id="formId" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12 mb-3">
                      <p className="m-0">
                        Continue With :- <b>{newPhone ? newPhone : UserNumber}</b>
                      </p>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="fname" className="form-label">
                          First Name *
                        </label>
                        <input
                          type="text"
                          {...register("fname", { required: true, minLength: 3, pattern: /^[a-zA-Z\s]*$/ })}
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ fname: "" });
                          }}
                          className="form-control"
                          name="fname"
                          id="fname"
                          placeholder="Enter First Name"
                          maxLength={30}
                        />
                        {chnageButton == true ? errors.fname?.type === "required" && <span className="error"> Please enter your first name </span> : ""}
                        {chnageButton == true ? errors.fname?.type === "minLength" && <span className="error">Please enter minimum 3 letters of your name</span> : ""}
                        {chnageButton == true ? errors.fname?.type === "pattern" && <span className="error"> Please enter valid first name </span> : ""}
                        {fnameError == true ? <span className="error"> Please enter your first name </span> : fnameError33 == true ? <span className="error">Please enter minimum 3 letters of your name</span> : fnameError22 == true ? <span className="error"> Please enter valid first name </span> : ""}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="lname" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          {...register("lastname", { pattern: /^[a-zA-Z\s]*$/ })}
                          className="form-control"
                          maxLength={30}
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ lastname: "" });
                          }}
                          name="lastname"
                          id="lname"
                          placeholder="Enter Last Name"
                        />

                        {chnageButton == true ? errors.lastname?.type === "pattern" && <span className="error"> Please enter valid last name </span> : ""}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email *
                        </label>
                        <input
                          type="text"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                          className="form-control"
                          name="email"
                          id="username"
                          placeholder="Enter Email "
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ email: "" });
                          }}
                          maxLength={80}
                        />
                        {chnageButton == true ? errors.email?.type === "required" && <span className="error"> Please enter your email</span> : ""}
                        {chnageButton == true ? errors.email?.type === "pattern" && <span className="error"> Please enter valid email </span> : ""}
                        {formEmailError == true ? <span className="error"> Please enter your email</span> : formEmailError22 == true ? <span className="error"> Please enter valid email </span> : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="altrphone" className="form-label">
                          Alternative Phone Number{" "}
                        </label>
                        <input
                          type="text"
                          {...register("altrphone", { maxLength: 10, minLength: 10, pattern: /^[0-9\b]+$/ })}
                          className="form-control"
                          name="altrphone"
                          id="altrphone"
                          placeholder="Enter Number"
                          maxLength="10"
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ altrphone: "" });
                          }}
                        />
                        {chnageButton == true ? errors.altrphone?.type === "minLength" && <span className="error">Please enter valid phone number</span> : ""}
                        {chnageButton == true ? errors.altrphone?.type === "maxLength" && <span className="error">Please enter valid phone number</span> : ""}
                        {chnageButton == true ? errors.altrphone?.type === "pattern" && <span className="error">Please enter valid phone number </span> : ""}
                        {wrongNumber22 == true ? <span className="error">Please enter valid phone number</span> : wrongNumber33 == true ? <span className="error">Please enter valid phone number</span> : wrongNumber == true ? <span className="error">Contact number and Alternative contacts should not be the same </span> : ""}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="dob" className="form-label">
                          Date Of Birth *
                        </label>
                        <Controller
                          name={"birthDate"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(value) => {
                                  setDOB(value);
                                  seterrorsdob(false);
                                }}
                                selected={dob ? dob : null}
                                placeholderText={"dd/mm/yyyy"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                maxDate={moment().subtract(21, "years").toDate()}
                                minDate={moment("01/01/1900").toDate()}
                              />
                            );
                          }}
                        />

                        {chnageButton == true ? errorsdob && <span className="error"> Please enter your date of birth</span> : ""}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="CurrentAddress" className="form-label">
                          Current Address *
                        </label>
                        <textarea
                          style={{ resize: "none" }}
                          name="CurrentAddress"
                          id="CurrentAddress"
                          className="form-control"
                          placeholder="Enter Current Address"
                          {...register("CurrentAddress", { required: "Please enter your current address" })}
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ CurrentAddress: "" });
                          }}
                          maxLength={250}
                        />
                        {chnageButton == true ? errors.CurrentAddress && <span className="error"> {errors.CurrentAddress.message} </span> : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="state" className="form-label">
                          State *
                        </label>
                        <select className="form-select" id="state" name="stateName" {...register("state", { required: "Please select your state" })} onChange={(e) => stateFun(e)}>
                          <option value="">Select State </option>
                          {statelist && statelist.map((i) => <option value={i._id}>{i.state}</option>)}
                        </select>
                        {chnageButton == true ? errors.state && <span className="error"> {errors.state.message} </span> : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">
                          City *
                        </label>
                        <select className="form-select" id="city" {...register("city", { required: "Please select your city" })}>
                          <option value="">Select City </option>

                          {citylist &&
                            citylist.map((i, index) => (
                              <option key={index} value={i}>
                                {i}
                              </option>
                            ))}
                        </select>
                        {chnageButton == true ? errors.city && <span className="error"> {errors.city.message} </span> : ""}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="pincode" className="form-label">
                          Pincode *
                        </label>
                        <input
                          type="text"
                          {...register("pincode", { required: chnageButton == false ? false : true, maxLength: 6, minLength: 6, pattern: /^[0-9\b]+$/ })}
                          className="form-control"
                          name="pincode"
                          id="pincode"
                          placeholder="Enter pincode"
                          maxLength="6"
                          onChange={(e) => {
                            e.target.value.trim() == "" && reset({ pincode: "" });
                          }}
                        />
                        {chnageButton == true ? errors.pincode?.type === "required" && <span className="error"> Please enter your pincode</span> : ""}
                        {chnageButton == true ? errors.pincode?.type === "minLength" && <span className="error">Please enter valid pincode</span> : ""}
                        {chnageButton == true ? errors.pincode?.type === "maxLength" && <span className="error">Please enter valid pincode</span> : ""}
                        {chnageButton == true ? errors.pincode?.type === "pattern" && <span className="error">Please enter valid pincode </span> : ""}
                      </div>
                    </div>
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary ms-3"
                        type="submit"
                        onClick={() => {
                          setFormFnameError("");
                          setFormEmailError("");
                          setChangeButton(true);
                          if (!dob) {
                            seterrorsdob(true);
                          }
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
